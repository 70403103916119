<template>
  <div class="main-container">
    <div class="main-header">
      <div class="logo-box">
        <img src="/images/logo.png" alt="">
      </div>
      <div class="name-box">韦氏儿童智力量表第四版中文版计分系统</div>
      <topMenu :num="2"></topMenu>
    </div>
    <div class="main-body column">
      <div class="header">
        <div class="title-box"><img src="/images/input-header-icon.png" alt=""><span
            class="name">完整版</span></div>
      </div>
      <div class="body">
        <div class="de" style="margin:0 0 40px 0;font-size: 14px"><span
            style="color: #832C75;font-weight: bold;margin-right: 10px">原始数据</span>ps: <span
            style="color: #832C75">*</span>为必填项
        </div>
        <a-form-model ref="form" :model="formModel" :rules="formRules" autoComplete="off" :label-col="labelCol"
                      :wrapper-col="wrapperCol" labelAlign="left">
          <a-row>
            <a-col :span="11">
              <a-form-model-item label="测试日期" prop="complete.test_date" >
                <a-date-picker :disabled="id ? true : false" style="width: 100%" class="cu-input1" @change="changeParentTestTime" format="YYYY-MM-DD"
                               v-model="formModel.complete.test_date"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="11">
              <a-form-model-item label="实际年龄" prop="complete.real_age">
                <a-input class="cu-input1" disabled placeholder="选择测试日期后将自动计算" v-model="formModel.complete.real_age"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <div class="line1"></div>
          <a-row>
            <a-col :span="11">
              <a-row>
                <a-col :span="24">
                  <a-form-model-item label="积木" prop="complete.score_bd">
                    <div class="flex">
                      <a-input-number v-model="formModel.complete.score_bd" class="cu-input1 input-number-100" :min="0"
                                      :max="68"/>
                      <span class="num-de"><0-68></span></div>
                  </a-form-model-item>
                  <a-form-model-item label="积木无速度加分" prop="complete.score_bdn">
                      <div class="flex">
                          <a-input-number  class="cu-input1 input-number-100" :min="0" :max="maxScoreBdn"
                                           v-model="formModel.complete.score_bdn"/>
                          <span class="num-de"><0-50></span></div>
                  </a-form-model-item>
                  <a-form-model-item label="类同" prop="complete.score_si">
                    <div class="flex">
                      <a-input-number v-model="formModel.complete.score_si" class="cu-input1 input-number-100" :min="0"
                                      :max="44"/>
                      <span class="num-de"><0-44></span></div>
                  </a-form-model-item>
                  <a-form-model-item label="顺序背数最大长度" prop="complete.score_dsf_max">
                      <div class="flex">
                          <a-input-number  class="cu-input1 input-number-100" :min="0" :max="11"
                                           v-model="formModel.complete.score_dsf_max"/>
                          <span class="num-de"><0-11></span></div>
                  </a-form-model-item>
                  <a-form-model-item label="顺序背数" prop="complete.score_dsf">
                      <div class="flex">
                          <a-input-number  class="cu-input1 input-number-100" :min="0" :max="20"
                                           v-model="formModel.complete.score_dsf" @change="changeBS"/>
                          <span class="num-de"><0-20></span></div>
                  </a-form-model-item>
                  <a-form-model-item label="倒序背数最大长度" prop="complete.score_dsb_max">
                      <div class="flex">
                          <a-input-number  class="cu-input1 input-number-100" :min="0" :max="9"
                                           v-model="formModel.complete.score_dsb_max"/>
                          <span class="num-de"><0-9></span></div>
                  </a-form-model-item>
                  <a-form-model-item label="倒序背数" prop="complete.score_dsb">
                      <div class="flex">
                          <a-input-number  class="cu-input1 input-number-100" :min="0" :max="18"
                                           v-model="formModel.complete.score_dsb" @change="changeBS"/>
                          <span class="num-de"><0-18></span></div>
                  </a-form-model-item>
                  <a-form-model-item label="背数" prop="complete.score_ds">
                      <div class="flex">
                          <a-input-number disabled v-model="formModel.complete.score_ds" class="cu-input1 input-number-100" :min="0"
                                          :max="38"/>
                          <span class="num-de"><0-38></span></div>
                  </a-form-model-item>
                  <a-form-model-item label="图画概念" prop="complete.score_pc">
                      <div class="flex">
                          <a-input-number  class="cu-input1 input-number-100" :min="0" :max="28"
                                           v-model="formModel.complete.score_pc"/>
                          <span class="num-de"><0-28></span></div>
                  </a-form-model-item>
                  <a-form-model-item label="译码" prop="complete.score_cd">
                    <div class="flex">
                      <a-input-number v-model="formModel.complete.score_cd" class="cu-input1 input-number-100" :min="0"
                                      :max="scoreCDMax"/>
                      <span class="num-de"><0-{{ scoreCDMax }}></span></div>
                  </a-form-model-item>
                  <a-form-model-item label="词汇" prop="complete.score_vc">
                    <div class="flex">
                      <a-input-number v-model="formModel.complete.score_vc" class="cu-input1 input-number-100" :min="0"
                                      :max="68"/>
                      <span class="num-de"><0-68></span></div>
                  </a-form-model-item>


                </a-col>
              </a-row>
            </a-col>
            <a-col :span="11" class="right-box" style="padding-left: 40px">
              <a-row>
                <a-col :span="24">

                    <a-form-model-item label="字母-数字" prop="complete.score_ln">
                        <div class="flex">
                            <a-input-number  class="cu-input1 input-number-100" :min="0" :max="30"
                                             v-model="formModel.complete.score_ln"/>
                            <span class="num-de"><0-30></span></div>
                    </a-form-model-item>
                    <a-form-model-item label="矩阵推理" prop="complete.score_mr">
                        <div class="flex">
                            <a-input-number  class="cu-input1 input-number-100" :min="0" :max="35"
                                             v-model="formModel.complete.score_mr"/>
                            <span class="num-de"><0-35></span></div>
                    </a-form-model-item>
                    <a-form-model-item label="理解" prop="complete.score_co">
                        <div class="flex">
                            <a-input-number  v-model="formModel.complete.score_co" class="cu-input1 input-number-100" :min="0"
                                            :max="42"/>
                            <span class="num-de"><0-42></span></div>
                    </a-form-model-item>
                    <a-form-model-item label="符号检索" prop="complete.score_ss">
                        <div class="flex">
                            <a-input-number  class="cu-input1 input-number-100" :min="0" :max="scoreSSMax"
                                             v-model="formModel.complete.score_ss"/>
                            <span class="num-de"><0-{{ scoreSSMax }}></span></div>
                    </a-form-model-item>
                    <a-form-model-item label="填图" prop="complete.score_pcm">
                        <div class="flex">
                            <a-input-number  v-model="formModel.complete.score_pcm" class="cu-input1 input-number-100" :min="0"
                                            :max="38"/>
                            <span class="num-de"><0-38></span></div>
                    </a-form-model-item>
                    <a-form-model-item label="随机划消" prop="complete.score_car">
                        <div class="flex">
                            <a-input-number @change="changeHX"  class="cu-input1 input-number-100" :min="0" :max="68"
                                            v-model="formModel.complete.score_car"/>
                            <span class="num-de"><0-68></span></div>
                    </a-form-model-item>
                    <a-form-model-item label="有序划消" prop="complete.score_cas">
                        <div class="flex">
                            <a-input-number @change="changeHX"  class="cu-input1 input-number-100" :min="0" :max="68"
                                            v-model="formModel.complete.score_cas"/>
                            <span class="num-de"><0-68></span></div>
                    </a-form-model-item>
                    <a-form-model-item label="划消" prop="complete.score_ca">
                        <div class="flex">
                            <a-input-number  disabled v-model="formModel.complete.score_ca" class="cu-input1 input-number-100" :min="0"
                                            :max="136"/>
                            <span class="num-de"><0-136></span></div>
                    </a-form-model-item>
                    <a-form-model-item label="常识" prop="complete.score_in">
                        <div class="flex">
                            <a-input-number v-model="formModel.complete.score_in" class="cu-input1 input-number-100" :min="0"
                                            :max="33"/>
                            <span class="num-de"><0-33></span></div>
                    </a-form-model-item>
                    <a-form-model-item label="算术" prop="complete.score_ar">
                        <div class="flex">
                            <a-input-number v-model="formModel.complete.score_ar" class="cu-input1 input-number-100" :min="0"
                                            :max="34"/>
                            <span class="num-de"><0-34></span></div>
                    </a-form-model-item>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <div style="border-bottom: 1px solid #f8f8f8;margin: 40px 0">&nbsp;</div>
          <a-row>
            <a-col :span="11">
              <a-row>
                <a-col>
                  <div class="right-title">比较类型</div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <a-form-model-item label="置信区间" prop="complete.confidence_probability">
                    <a-select v-model="formModel.complete.confidence_probability" placeholder="请选择" style="width: 260px;" dropdownClassName="cu-select">
                      <a-select-option value="90">90%</a-select-option>
                      <a-select-option value="95">95%</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <a-form-model-item label="统计显著性水平" prop="complete.significance_level">
                    <a-select v-model="formModel.complete.significance_level" placeholder="请选择(强项/弱项)" style="width: 260px" dropdownClassName="cu-select">
                      <a-select-option value="0.05">0.05</a-select-option>
                      <a-select-option value="0.15">0.15</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <a-form-model-item label="比较基础" prop="complete.comparison_basis">
                    <a-select v-model="formModel.complete.comparison_basis" placeholder="请选择比较基础" style="width: 260px" dropdownClassName="cu-select">
                      <a-select-option value="1">全样本</a-select-option>
                      <a-select-option value="2">能力水平</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="11" class="right-box" style="padding-left: 40px">
              <a-row>
                <a-col :span="24">
                  <div style="font-size: 12px;color: #832C75;margin-bottom: 20px;padding-left: 5px">存在无效的原始分数时</div>
                  <a-form-model-item label="计算方法" prop="complete.computing_method">
                    <a-radio-group name="radioGroup" v-model="formModel.complete.computing_method" @change="changeCM">
                      <a-radio :value="1">替代分测验</a-radio>
                      <a-radio :value="2">比例估算法</a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                </a-col>
              </a-row>
<!--              <a-row>-->
<!--                <a-col :span="24">-->
<!--                  <div style="font-size: 12px;color: #832C75;margin-bottom: 20px;padding-left: 5px">找虫缺失时,选择以下补充</div>-->
<!--                  <a-form-model-item label="补充方式" prop="complete.supplement_type">-->
<!--                    <a-radio-group name="radioGroup" v-model="formModel.complete.supplement_type">-->
<!--                      <a-radio :value="1">划消</a-radio>-->
<!--                      <a-radio :value="2">动物译码</a-radio>-->
<!--                    </a-radio-group>-->
<!--                  </a-form-model-item>-->
<!--                </a-col>-->
<!--              </a-row>-->
            </a-col>
          </a-row>
          <div class="line1" style="margin-bottom: 40px;margin-top: 10px"></div>
          <div style="display: flex;align-items: center;justify-content: center">
<!--            <div class="btn sh" @click="goSelectAge"><img src="/images/btn-left-icon.png" alt="" style="height: 12px;margin-right: 5px"><span>返回选择页面</span></div>-->
            <div class="btn" @click="actionData">{{ id != '' ? '编辑并生成报告' : '保存并生成报告' }}</div>

          </div>
        </a-form-model>
      </div>
    </div>
    <footer-bottom></footer-bottom>
  </div>
</template>

<script>
import {Save, Detail, Update} from '../../commonApi';
import {base, complete, completeRule} from "../complete-field";
import {message} from "ant-design-vue";
import * as Api from "../base-info/api";
import moment from "moment";

export default {
  data() {
    return {
        scoreSSMax:60,
        scoreCDMax:119,
        labelCol: {span: 8, style: {color: '#7E8589', fontWeight: '500'}},
        wrapperCol: {span: 14},
        formModel: {
            base: Object.assign({}, base),
            complete: Object.assign({}, complete),
        },
        formRules: Object.assign({}, {...completeRule}),
        isUnit: 1, // 是否关联
        bindListVisible: false,
        id: '',
        start:null
    }
  },
  computed: {
    maxScoreBdn() {
      return Math.min(this.formModel.complete.score_bd, 50);
    }
  },
  mounted() {

    // 编辑
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail()
    } else {
      this.formModel.base = JSON.parse(window.localStorage.getItem('base_info'));
    }
    if (this.$route.query && this.$route.query.source) {
      this.source = this.$route.query.source;
      this.start = this.$route.query.start;
    }
  },
  methods: {
    // 背数 = 顺序背数 + 倒序背数
    changeBS() {
      if (/^[0-9]+$/.test(this.formModel.complete.score_dsf) && /^[0-9]+$/.test(this.formModel.complete.score_dsb)) {
        this.formModel.complete.score_ds = parseInt(this.formModel.complete.score_dsf) + parseInt(this.formModel.complete.score_dsb);
      } else {
        this.formModel.complete.score_ds = '';
      }
    },
    // 划消 = 有序划消 + 随机划消
    changeHX() {
      if (/^[0-9]+$/.test(this.formModel.complete.score_car) && /^[0-9]+$/.test(this.formModel.complete.score_cas)) {
        this.formModel.complete.score_ca = parseInt(this.formModel.complete.score_car) + parseInt(this.formModel.complete.score_cas);
      } else {
        this.formModel.complete.score_ca = '';
      }
    },
    changeHx() {
      // if (/^[0-9]+$/.test(this.formModel.complete.score_car) && /^[0-9]+$/.test(this.formModel.complete.score_cas)) {
      //   this.formModel.complete.score_ca = parseInt(this.formModel.complete.score_car) + parseInt(this.formModel.complete.score_cas)
      // } else {
      //   this.formModel.complete.score_ca = '';
      // }
    },
    async getDetail() {
      const res = await Detail({id: this.id});
      if (res && res.code == 0) {
        this.formModel.complete = Object.assign({}, res.wiscAssessment);
        this.formModel.base = {
          id: res.wiscAssessment.id,
          archives_num: res.wiscAssessment.archives_num,
          scoring_num: res.wiscAssessment.scoring_num,
          name: res.wiscAssessment.name,
          examiner_name: res.wiscAssessment.examiner_name,
          gender: res.wiscAssessment.gender,
          is_minorities: res.wiscAssessment.is_minorities,
          hand_habit: res.wiscAssessment.hand_habit,
          exam_address: res.wiscAssessment.exam_address,
          born_date: res.wiscAssessment.born_date,
          remark: res.wiscAssessment.remark,
          grade: res.wiscAssessment.grade,
          parent_culture: res.wiscAssessment.parent_culture,
        }
        this.formModel.complete.computing_method = parseInt(this.formModel.complete.computing_method)
        this.formModel.complete.supplement_type = parseInt(this.formModel.complete.supplement_type)
        this.formModel.complete.comparison_basis = parseInt(this.formModel.complete.comparison_basis)
        this.formModel.complete.confidence_probability = parseInt(this.formModel.complete.confidence_probability)
      }
    },
    goSelectAge() {
      this.$router.push({path: '/select-age'})
    },
    changeComputingMethod() {
    
    },
    async actionData() {
      this.changeComputingMethod()
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let res = null;

          if (this.id) {
            res = await Update({base: this.formModel.base, assessment: this.formModel.complete});
          } else {
            res = await Save({base: this.formModel.base, assessment: this.formModel.complete});
          }
          if (res && res.code == 0) {
            window.localStorage.removeItem('base_info');
            message.success({content: res.message});
            setTimeout(()=> {
              if (this.id == '') {
                this.$router.push({ path: "/" });
              }
              else if (this.id != '' && this.source == 'index') {
                this.$router.push({ name:'Index', params:{ start:this.start }})
                // this.$router.push({ path: "/index",params:{start:this.start} });
              }
              else if (this.id != '' && this.source == 'history') {
                this.$router.push({ path: "/maintain-history-report" });
              }

            }, 1500);
          }
        } else {
          message.error({content: '表单验证未通过'});
        }
      });
    },
    async getRealAge(type, time) {
      const res = await Api.GetRealAge({born_time: this.formModel.base.born_date, test_date: time});
    //   console.log(res);
      if (res && res.code == 0) {
        let year = res.ageDetail.split('-')[0]
        if (year >= 6 && year < 8){
            this.scoreSSMax = 45
            this.scoreCDMax = 65
        } else {
            this.scoreSSMax = 60
            this.scoreCDMax = 119

        }
        this.formModel.complete.real_age = res.realAge;
      }
    },
    changeParentTestTime(e, s) {
      if (s) {
        this.formModel.complete.test_date = s;
       
        this.getRealAge('parent', s);
      } else {
        this.formModel.complete.real_age = ''
      }
    },
    // 当选择了比例估算法，不用再选择补充方式
    changeCM(e) {
      // if (e && e.target.value == '2') {
      //   this.formRules['complete.supplement_type'][0]['required'] = false;
      //   this.$refs["form"].clearValidate('complete.supplement_type');
      //   this.formModel.complete.supplement_type = '';
      // } else {
      //   this.formRules['complete.supplement_type'][0]['required'] = true;
      // }
    }
  }
};
</script>
<style>

</style>
<style scoped>
.main-body {
  background-image: url("/images/maintain-bg.png");
  align-items: flex-start;
  justify-content: flex-start;
}

.line1 {
  border: 1px solid #f8f8f8;
  margin-bottom: 20px;
}

.ant-form-item-control .ant-form-item-children {
  display: flex;
}

.num-de {
  font-size: 12px;
  font-weight: 400;
  color: #999A9A;
  width: 100px;
  text-align: center;
}

.flex {
  display: flex;
}

.right-box {
  height: 100%;
  display: flex;
  flex-direction: column;
//height: 460px; border-left: 1px solid #eee;
  padding-left: 70px;
  justify-content: center;
}

.right-title {
  font-size: 14px;
  font-weight: 600;
  color: #832C75;
  margin-bottom: 20px;
}

.cu-select {
  line-height: 42px;
  height: 42px;
}

.btn {
  background: #832C75;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
  padding: 0 80px;
}

.btn.sh {
  border: 1px solid #832B75;
  border-radius: 5px;
  font-weight: 400;
  color: #832B75;
  line-height: 45px;
  background: #FFFFFF;
  display: inline;
  display: flex;
  align-items: center;
}

.ant-form-item {
  margin-bottom: 12px !important;
}
</style>
